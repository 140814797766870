<template>
  <div class="min-h-100vh bg-hex-F5F7F9">
    <!-- 搜索 -->
    <van-search
      class="sticky left-0 top-0 z-1"
      v-model="keyword"
      placeholder="姓名、订单编号"
      @search="search"
      @clear="onRefresh()"
    />

    <!-- 列表 -->
    <div ref="list" class="mx-12 mt-12">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : '暂无数据'"
          @load="onLoad"
        >
          <van-radio-group v-model="selectInfo">
            <!-- 项 -->
            <CaseItem
              v-for="(item, index) in list"
              :key="index"
              :item="item"
            >
              <van-radio slot="topSuffix" :name="item"></van-radio>
            </CaseItem>
          </van-radio-group>

          <!--  -->
        </van-list>
      </van-pull-refresh>
    </div>

    <!-- 留白 -->
    <div class="h-56"></div>

    <!-- 选择 -->
    <div
      v-if="selectInfo"
      class="fixed left-0 bottom-0 w-100vw bg-white py-8 px-16"
    >
      <van-button size="large" color="#1890FF" @click="add()">
        选择
      </van-button>
    </div>

    <!--  -->
  </div>
</template>
<script>
import { columnAmount } from "@/utils/tool";

import { customerCaseDetailsPagePost } from "@/api";

export default {
  components: {
    CaseItem: () => import("@/views/Collection/components/CaseItem.vue"),
  },
  data() {
    return {
      keyword: "",
      selectInfo: null,

      /**
       * 列表
       */
      list: [],
      pageNo: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      //
    };
  },
  methods: {
    columnAmount,

    // 新增
    add() {
      sessionStorage.newReminderItem = JSON.stringify({
        ...this.selectInfo,
        collectionSource: this.$route.query.type,
      });
      this.$router.push(`/newReminder`);
    },

    // 上拉加载
    onLoad() {
      (this.pageNo += 1) && this.getList();
    },

    // 下拉刷新
    onRefresh() {
      (this.pageNo = 1) && this.getList();
    },

    // 搜索
    search(v) {
      if (!v) {
        this.$toast.fail("请输入关键字");
        return;
      }

      this.onRefresh();

      //
    },

    // 获取 列表
    async getList({ pageNo = this.pageNo, pageSize = this.pageSize } = {}) {
      try {
        if (pageNo == 1) {
          this.$toast.loading({ duration: 0 });
          this.$refs["list"].scrollTo({ top: 0, behavior: "smooth" });
        }

        const data = await customerCaseDetailsPagePost({
          pageNo,
          pageSize,
          searchContent: this.keyword,
          collectionSource: this.$route.query.type,
        });

        this.refreshing = this.loading = false;
        const len = data.list.length;

        this.list = data.pageNo == 1 ? data.list : [...this.list, ...data.list];
        this.finished = len < pageSize;

        //
      } catch (err) {
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }
    },

    //
  },
};
</script>
<style lang="scss" scoped>


</style>